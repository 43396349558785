import EventEmitter from 'events'
import TypedEmitter from 'typed-emitter'

type LoadingOverlayEvents = {
	'loading:start': (message?: string) => void
	'loading:stop': () => void
}

type ImagePreviewEvents = {
	'imagePreview:change': (files: FileList | null) => void
}

export type EmitterEvents = LoadingOverlayEvents & ImagePreviewEvents

export const emitter = new EventEmitter() as TypedEmitter<EmitterEvents>

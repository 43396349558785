import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import { emitter } from '../../../scripts'

export const identifier = 'image-preview'

const ImagePreviewComponent: TComponentInit = (element) => {
	const previewWrapper = element.querySelector('[data-preview]')
	const fallbackWrapper = element.querySelector('[data-fallback]')

	// generate previews for each selected file
	// and append it to the preview wrapper element
	const loadPreviews = (files: FileList | null) => {
		console.log(files)
		if (!previewWrapper) {
			throw new Error()
		}

		previewWrapper.innerHTML = ''

		console.log(files)
		if (!files) {
			return
		}

		;[...files].forEach((file) => {
			const previewItemElement = document.createElement('figure')
			previewItemElement.classList.add(`${identifier}__preview-item`)
			if (file.type.match('application/pdf') !== null) {
				throw new Error()
			} else if (file.type.match('image/') !== null) {
				const fileUrl = window.URL.createObjectURL(file)
				const previewImage = document.createElement('img')

				previewImage.classList.add(`${identifier}__preview-item-image`)
				previewImage.onload = () => {
					window.URL.revokeObjectURL(fileUrl)
				}
				previewImage.src = fileUrl

				const previewCaption = document.createElement('figcaption')
				previewCaption.classList.add(
					`${identifier}__preview-item-name`,
					'visually-hidden',
				)
				previewCaption.innerText = `${file.name}`

				previewItemElement.append(previewCaption)
				previewItemElement.append(previewImage)
			} else {
				throw new Error()
			}

			previewWrapper?.append(previewItemElement)
		})
	}

	const onChange = (files: FileList | null) => {
		emitter.emit('loading:start')
		console.log('sfasf')

		try {
			loadPreviews(files ?? null)

			fallbackWrapper?.setAttribute('hidden', '')
			previewWrapper?.removeAttribute('hidden')
		} catch {
			previewWrapper?.setAttribute('hidden', '')
			fallbackWrapper?.removeAttribute('hidden')
		}

		emitter.emit('loading:stop')
	}

	function init() {
		emitter.on('imagePreview:change', onChange)
	}

	function destroy() {
		emitter.off('imagePreview:change', onChange)
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, ImagePreviewComponent)

export default {
	identifier,
	ImagePreviewComponent,
}

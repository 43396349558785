import {
	registerComponent,
	TComponentInit,
	findAndInitAllComponents,
} from '@hrmony/component-library/scripts'
import type { EssenszuschussUploadOverlayConfig } from './EssenszuschussUploadOverlay.shared'
import dialogPolyfill from 'dialog-polyfill'

export const identifier = 'essenszuschuss-upload-overlay'

const EssenszuschussUploadOverlayComponent: TComponentInit = (element) => {
	const config: EssenszuschussUploadOverlayConfig = JSON.parse(
		element.getAttribute('data-component-config')!,
	)
	const openTrigger = element.querySelector(
		'[data-essenszuschuss-upload-overlay-open]',
	)
	const dialog = element.querySelector<HTMLDialogElement>(
		'[data-essenszuschuss-upload-overlay-dialog]',
	)
	let closeTrigger: Element | null = null
	let isUploadFormLoaded = false
	let openUploadFromInstantly = false

	if (!dialog) {
		return {
			element,
			init: () => {},
			destroy: () => {},
		}
	}

	dialogPolyfill.registerDialog(dialog)

	const onOpen = (event?: Event) => {
		event?.preventDefault()

		console.log({ isUploadFormLoaded })

		if (isUploadFormLoaded) {
			dialog.showModal()
		} else {
			openUploadFromInstantly = true
		}
	}

	const onClose = (event?: Event) => {
		event?.preventDefault()

		dialog.close()
	}

	const initUploadOverlay = () => {
		findAndInitAllComponents(dialog)

		closeTrigger = dialog.querySelector('[data-overlay-close]')

		closeTrigger?.addEventListener('click', onClose)
	}

	const destroyUploadOverlay = () => {
		onClose()

		dialog.innerHTML = ''
		isUploadFormLoaded = false
	}

	const loadUploadOverlay = async () => {
		if (isUploadFormLoaded) {
			return
		}

		try {
			setTimeout(async () => {
				const response = await fetch(`${config.action}?configOnly=true`, {
					headers: {
						'HX-Request': 'true',
					},
				})
				console.log({ openUploadFromInstantly })
				dialog.innerHTML = await response.text()
				initUploadOverlay()

				isUploadFormLoaded = true

				if (openUploadFromInstantly) {
					onOpen()
				}
			}, 3000)
		} catch (error) {
			console.log(error)
		}
	}

	const init = () => {
		loadUploadOverlay()

		openTrigger?.addEventListener('click', onOpen)
	}

	function destroy() {
		destroyUploadOverlay()

		openTrigger?.removeEventListener('click', onOpen)
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, EssenszuschussUploadOverlayComponent)

export default {
	identifier,
	EssenszuschussUploadOverlayComponent,
}

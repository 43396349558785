import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import { emitter } from '../../../../scripts'

export const identifier = 'essenszuschuss-upload'

const EssenszuschussUploadComponent: TComponentInit = (element) => {
	const fileInput = element.querySelector<HTMLInputElement>(
		'[data-component-essenszuschuss-upload-file-input]',
	)
	console.log(fileInput)
	const onChange = () => {
		console.log('change', fileInput)
		emitter.emit('imagePreview:change', fileInput?.files ?? null)
	}

	const init = () => {
		fileInput?.addEventListener('change', onChange)
	}

	function destroy() {
		fileInput?.removeEventListener('change', onChange)
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, EssenszuschussUploadComponent)

export default {
	identifier,
	EssenszuschussUploadComponent,
}

import 'vite/modulepreload-polyfill'
import '../styles/main.scss'

import {
	findAndInitAllComponents,
	initSentry,
} from '@hrmony/component-library/scripts'
import { logging } from '~scripts'

// Components
import '~components/DateNavigation/DateNavigation.component'
import '~components/EssenszuschussUploadOverlay/EssenszuschussUploadOverlay.component'
import '~components/Form/Form.component'
import '~components/ImagePreview/ImagePreview.component'
import '~components/LoadingOverlay/LoadingOverlay.component'
import '~components/OfflineBanner/OfflineBanner.component'
import '~components/Overlay/Overlay.component'
import '~components/VoucherCode/VoucherCode.component'
import '~components/SnapSlider/SnapSlider.component'
import '~pages/sachbezug/SachbezugPartnerdetail/VoucherSelect.component'
import '~pages/essenszuschuss/EssenszuschussUpload/EssenszuschussUpload.component'

// init
const registerServiceWorker = (window: Window): void => {
	try {
		navigator.serviceWorker?.getRegistrations().then((registrations) => {
			for (const registration of registrations) {
				// Unregister all service worker with a hashed file name
				if (!registration.active?.scriptURL.match(/service-worker\.js$/)) {
					registration.unregister()
				}
			}
		})
	} catch {
		//
	}

	const serviceWorkerScope = window.__DEV__
		? '/src/scripts/service-worker/'
		: '/'
	try {
		if (
			'serviceWorker' in window.navigator &&
			window._hrmonyConfig?.serviceWorker?.url
		) {
			window.navigator.serviceWorker
				.register(`/${window._hrmonyConfig.serviceWorker.url}`, {
					scope: serviceWorkerScope,
				})
				.then(() => {})
				.catch((error) => {
					logging.logError({
						name: `Register Service Worker (${(error as Error).name})`,
						description: (error as Error).message,
					})
				})
		}
	} catch (error) {
		logging.logError({
			name: `Register Service Worker (${(error as Error).name})`,
			description: (error as Error).message,
		})
	}
}

initSentry('mba')

registerServiceWorker(window)

findAndInitAllComponents()
